import { Currency } from 'src/types/currency';
import { axiosInstance } from 'src/utils/axios';

export const getMerchantId = async (currency: Currency) => {
  const widgetPath = document.location.href;

  try {
    const { data } = await axiosInstance.get<{ aid: string }>(
      '/merchant/details',
      {
        params: { currency, widgetPath },
      }
    );

    return data.aid;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Get merchant id failed', e);

    return '';
  }
};
