import { useState } from 'react';
import { getMerchantId } from 'src/api/get-merchant-id';
import { Currency } from 'src/types/currency';

export const useGetMerchantId = (): ((
  currency: Currency
) => Promise<string>) => {
  const [merchantId, setMetchantId] = useState('');

  return (currency) =>
    new Promise<string>((resolve) => {
      if (merchantId !== '') {
        resolve(merchantId);
      } else {
        getMerchantId(currency).then((id) => {
          setMetchantId(id);
          resolve(id);
        });
      }
    });
};
