import { datadogRum } from '@datadog/browser-rum';
import React, { useEffect } from 'react';
import { lazily } from 'react-lazily';
import NumberFormat from 'react-number-format';
import { ConditionSuspense } from 'src/components/condition-suspense';
import { Currency } from 'src/components/currency';
import { Portal } from 'src/components/portal';
import { ACTIONS, WIDGET_INITIALIZED } from 'src/constants/common';
import { COUNTRY_NAME_BY_CURRENCY_MAP } from 'src/constants/country-name-by-currency-map';
import { INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO } from 'src/constants/learn-more';
import {
  AnalyticsProvider,
  useAnalyticsContext,
} from 'src/context/analytics-context';
import {
  AppProvider,
  MerchantProps,
  useAppContext,
} from 'src/context/store/app-provider';
import { useLocale } from 'src/hooks/use-locale';
import { useLogSnippetCartRenderedEvent } from 'src/hooks/use-log-snippet-cart-rendered-event';
import { useOnExperimentsReady } from 'src/hooks/use-on-experiments-ready';
import { useTogglerState } from 'src/hooks/use-toggler-state';
import { mobileBridge } from 'src/services/mobile-bridge';
import { allowedCurrencies, CurrencyType } from 'src/types/currency';
import { SegmentEventType } from 'src/types/segment';
import { createNormalizeCheckingNumber } from 'src/utils/create-normalize-checking-number';
import { initializeDatadogRUMWidgets } from 'src/utils/datadog';
import { delayDecorator } from 'src/utils/delay-decorator';
import { getDeviceType } from 'src/utils/get-device-type';
import { getLearnMoreLink } from 'src/utils/get-learn-more-link';
import { getPriceSplitOf } from 'src/utils/get-price-split-of';
import { isMobileAppContext } from 'src/utils/is-mobile-app-context';
import { renderToDecorator } from 'src/utils/render-to-decorator';

import styles from './styles.css';

const { NonStandardPaymentPlansWidget } = lazily(
  () => import('src/components/non-standard-payment-plans-widget')
);
const { SnippetDefault } = lazily(
  () => import('src/components/snippet-default')
);
const { PopupLearnMoreWithSchedule } = lazily(
  () => import('src/components/popup-learn-more-with-schedule')
);

const { LongTermPlans } = lazily(
  () => import('src/components/long-term-plans-widget')
);

const DD_STOP_RECORDING_TIMEOUT = 2000;

export function DefaultPromoWidget() {
  const { onSegmentLog, sendLearnMoreOpenEvent } = useAnalyticsContext();
  const {
    currency,
    price,
    installmentsCount,
    publicKey,
    merchantCode,
    formattedCurrency,
    dispatch,
    priceRaw,
    aid,
    offers,
    lang,
    experiments,
  } = useAppContext();
  const pricePart = getPriceSplitOf(price, currency, installmentsCount);
  const [isShowPopup, { switchOn, switchOff }] = useTogglerState();
  const [, { switchOn: suspenseOn, switchOff: suspenseOff }] =
    useTogglerState();
  const t = useLocale();

  useOnExperimentsReady(initializeDatadogRUMWidgets);

  useLogSnippetCartRenderedEvent({
    planSelected: installmentsCount,
    snippetType: [3, 4].includes(installmentsCount)
      ? 'fullInformation'
      : 'notFullInformation',
  });

  const preparedPrice = createNormalizeCheckingNumber(
    'Price argument is not a number',
    0
  )(priceRaw);

  const isKSA = formattedCurrency === CurrencyType.SAR;
  const isValidCountry =
    !!formattedCurrency && allowedCurrencies.includes(formattedCurrency);
  const hasConfiguredPlans = offers.installments.length !== 0;

  const isPiXWidget =
    isValidCountry &&
    formattedCurrency !== CurrencyType.AED &&
    !hasConfiguredPlans &&
    INSTALLMENTS_COUNT_LIST_FOR_DEFAULT_PROMO.includes(installmentsCount);

  const isLongTermWidget =
    hasConfiguredPlans || formattedCurrency === CurrencyType.AED;

  const isNonDefaultWidget = !isPiXWidget && !isLongTermWidget;

  useEffect(() => {
    if (isShowPopup) {
      onSegmentLog?.({
        event: SegmentEventType.LEARN_MORE_POP_UP_OPENED,
        publicKey: publicKey ? 'yes' : 'no',
        platformType: getDeviceType(),
        productType: 'installments',
        merchantIntegrationType: 'snippetAndPopup',
        planSelected: installmentsCount,
        popupType: 'standardWithoutInfo',
        merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
        merchantCode,
      });
    }
  }, [
    currency,
    installmentsCount,
    isShowPopup,
    merchantCode,
    onSegmentLog,
    publicKey,
  ]);

  useEffect(() => {
    dispatch({
      type: ACTIONS.PREPARE_SCHEDULE_POPUP,
    });
  }, [dispatch]);

  const clickOnSnippet = () => {
    onSegmentLog?.({
      event: SegmentEventType.LEARN_MORE_CLICKED,
      publicKey: publicKey ? 'yes' : 'no',
      platformType: getDeviceType(),
      productType: 'installments',
      merchantIntegrationType: 'snippetAndPopup',
      planSelected: installmentsCount,
      snippetType: [3, 4].includes(installmentsCount)
        ? 'fullInformation'
        : 'notFullInformation',
      merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
      merchantCode,
    });

    datadogRum.startSessionReplayRecording();
  };

  const openLearnMorePage = () => {
    mobileBridge.openUrl(
      getLearnMoreLink({
        price: priceRaw,
        currency: formattedCurrency,
        lang,
      }),
      { experiments, aid, offers }
    );
  };

  const openLearnMoreDialog = () => {
    switchOn();
    suspenseOn();
    clickOnSnippet();

    sendLearnMoreOpenEvent(aid);
  };

  const openLearnMore = () => {
    const action = isMobileAppContext ? openLearnMorePage : openLearnMoreDialog;

    action();
  };

  const close = (action: 'cross' | 'inactive zone' | 'continue') => {
    onSegmentLog?.({
      event: SegmentEventType.LEARN_MORE_POP_UP_CLOSED,
      publicKey: publicKey ? 'yes' : 'no',
      platformType: getDeviceType(),
      productType: 'installments',
      merchantIntegrationType: 'snippetAndPopup',
      planSelected: installmentsCount,
      popupType: 'standardWithInfo',
      merchantCountry: COUNTRY_NAME_BY_CURRENCY_MAP[currency],
      action,
      merchantCode,
    });
    switchOff();
    // recording starts when we are opening Learn More, stop when we close Learn More
    // we wait for a while just to see how our widget looks (without Learn More)
    setTimeout(() => {
      datadogRum.stopSessionReplayRecording();
      datadogRum.stopSession();
    }, DD_STOP_RECORDING_TIMEOUT);
  };

  const params = (newPrice: string) => ({
    price: newPrice,
    installmentsCount,
  });

  const getText = (formattedValue: string) => {
    if (!preparedPrice) {
      return t('tabbySplit.3_how_it_works');
    }
    const shariahText = isKSA ? `${t('default.shariahCompliant')}.` : '';
    return (
      <>
        {t('paid_plans.as_low_as_per_month_ltp', params(formattedValue), {
          strong: (string) => <strong>{string}</strong>,
          currency: () => <Currency currency={currency} />,
        })}
        . {shariahText}
      </>
    );
  };

  const getLinkText = () => {
    if (!preparedPrice) {
      return t('default.all_options');
    }

    return t('default.learnMore');
  };

  if (isLongTermWidget) {
    return (
      <ConditionSuspense condition={isLongTermWidget}>
        <LongTermPlans />
      </ConditionSuspense>
    );
  }

  if (isNonDefaultWidget) {
    return (
      <ConditionSuspense condition={isNonDefaultWidget}>
        <NonStandardPaymentPlansWidget />
      </ConditionSuspense>
    );
  }

  return (
    <div className={styles.tabbySnippet}>
      <ConditionSuspense condition={!isNonDefaultWidget}>
        <SnippetDefault
          message={
            <NumberFormat
              /* Need key to recall renderText with new pricePart */
              key={pricePart}
              value={pricePart}
              renderText={getText}
              displayType="text"
              allowNegative={false}
            />
          }
          linkText={getLinkText()}
          onClick={openLearnMore}
        />
      </ConditionSuspense>

      <Portal to="body">
        <ConditionSuspense condition={isPiXWidget}>
          <PopupLearnMoreWithSchedule
            isShow={isShowPopup}
            onClose={() => close('cross')}
            onContinue={() => close('continue')}
            onInactiveZoneClick={() => close('inactive zone')}
            onCloseAfter={suspenseOff}
          />
        </ConditionSuspense>
      </Portal>
    </div>
  );
}

declare global {
  interface Window {
    TabbyPromoDefault: (props: { selector: string } & MerchantProps) => void;
  }
}

window.TabbyPromoDefault = ({ selector, ...props }) => {
  datadogRum.addAction(WIDGET_INITIALIZED, {
    constructorName: 'TabbyPromoDefault',
    ...props,
  });

  delayDecorator(() => {
    renderToDecorator(
      selector,
      <AppProvider {...props}>
        <AnalyticsProvider props={props}>
          <DefaultPromoWidget />
        </AnalyticsProvider>
      </AppProvider>
    );
  });
};
