const URL_TO_LEARN_MORE_WEBVIEW = `${process.env.CHECKOUT_HOST}/promos/product-page/installments`;

export const getLearnMoreLink = ({ lang, ...queryParams }) => {
  const searchParams = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    searchParams.append(key, value);
  });

  return `${URL_TO_LEARN_MORE_WEBVIEW}/${lang}/?${searchParams.toString()}`;
};
